<template>
  <v-menu
    v-bind="menuProps"
    content-class="inheritance-source-menu"
    bottom
    offset-y
    nudge-left="40"
    open-on-hover
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :on="on"
        :attrs="attrs"
        :has-source="hasInheritanceSource"
      />
    </template>
    <v-container
      v-if="hasInheritanceSource"
      class="inheritance-source-text"
      data-test-id="inheritanceSourceMenu"
    >
      <div class="inheritance-source-header">
        <v-icon small left>mdi-graph</v-icon>
        <div class="no-inheritance-source text-no-wrap">Inherit from</div>
        <v-spacer />
        <v-btn
          v-if="enableKeyPatternLink"
          icon
          x-small
          color="primary"
          class="ml-2"
          title="Open inheritance source"
          data-test-id="openInheritanceSourceBtn"
          :href="inheritanceSource"
        >
          <v-icon small>mdi-link</v-icon>
        </v-btn>
      </div>
      <v-divider class="my-1" />
      <div class="d-flex flex-col">
        <div class="font-weight-bold">Domain:</div>
        <div class="ml-1" data-test-id="inheritanceSourceDomain">
          {{ inheritedDomain }}
        </div>
      </div>
      <div class="d-flex flex-col">
        <div class="font-weight-bold">Config.:</div>
        <div class="ml-1">
          <div data-test-id="inheritanceSourceKey">
            {{ inheritedKeyPattern }}
          </div>
        </div>
      </div>
    </v-container>
    <v-container v-else class="inheritance-source-text">
      <div class="text--disabled no-inheritance-source">
        No inheritance source found
      </div>
    </v-container>
  </v-menu>
</template>

<script>
export default {
  props: {
    inheritedDomain: {
      type: String,
      required: false,
    },

    inheritedKeyPattern: {
      type: String,
      required: false,
    },

    keyPattern: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      updateMenuProps: false,
    };
  },

  mounted() {
    const self = this;
    window.addEventListener(
      "scroll",
      () => {
        // called when the window is scrolled.
        self.updateMenuProps = !self.updateMenuProps;
      },
      true
    );
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    inheritanceSource() {
      const route = this.$router.resolve({
        ...this.$route,
        name: this.$route.name,
        params: {
          ...this.$route.params,
          domain: this.inheritedDomain,
          pattern: this.inheritedKeyPattern,
          key: this.inheritedKeyPattern,
        },
      });
      return route.href;
    },

    hasInheritanceSource() {
      return !!this.inheritedDomain && !!this.inheritedKeyPattern;
    },

    enableKeyPatternLink() {
      return (
        this.inheritedDomain !== "root" &&
        (this.keyPattern !== this.inheritedKeyPattern ||
          this.inheritedDomain !== this.selectedDomain)
      );
    },

    menuProps() {
      this.updateMenuProps;
      const menu = this.$children?.[0];
      if (!menu?.isActive) return;
      const element = menu?.activatorElement;
      if (!element) return undefined;
      const rect = element.getBoundingClientRect();
      return {
        positionX: rect.x,
        positionY: rect.y,
      };
    },
  },
};
</script>

<style scoped>
.inheritance-source-text {
  background-color: white;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.87);
}

.inheritance-source-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>