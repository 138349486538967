<template>
  <div v-if="buildingTree" class="data-reader-loading-overlay">
    <v-overlay absolute color="white">
      <v-progress-circular indeterminate size="96" color="primary" />
    </v-overlay>
  </div>
  <div v-else class="data-reader-container">
    <div v-if="!disableJson" class="data-reader-toolbar">
      <v-switch
        v-model="previewAsJson"
        inset
        hide-details
        label="Preview as JSON"
        class="data-reader-control"
      />

      <div v-if="uimodel && previewAsJson" class="d-flex">
        <v-divider vertical class="mx-2" />
        <v-checkbox
          v-model="copyEnabled"
          hide-details
          label="Enable copy"
          class="data-reader-control mr-3"
        />

        <div class="warning--text">
          <v-icon color="warning">mdi-alert</v-icon>
          Copying data from one key to another could render the inheritance
          functionality useless, so use with caution!
        </div>
      </div>
    </div>
    <div v-if="previewAsJson">
      <pre
        :class="{
          'data-reader-json': true,
          'copy-enabled': copyEnabled || !uimodel,
        }"
        >{{ formattedJSON }}</pre
      >
    </div>
    <v-treeview
      v-else
      :items="tree"
      open-all
      open-on-click
      dense
      hoverable
      ref="readerTree"
      class="data-reader"
    >
      <template v-slot:label="{ item }">
        <div
          :class="{
            'd-flex': true,
            'justify-start': true,
            'align-center': true,
            invalid: item.invalid,
          }"
        >
          <div
            class="data-reader-label"
            :data-test-id="'tree_node_' + item.path + '_name'"
          >
            {{ item.name + (item.isSimple ? ":" : "") }}
          </div>

          <div
            v-if="item.invalid"
            class="data-reader-value"
            :data-test-id="'tree_node_' + item.path + '_value'"
          >
            INVALID DATA
          </div>
          <div
            v-else-if="item.isI18n"
            class="data-reader-localization-list"
            :data-test-id="'tree_node_' + item.path + '_value'"
          >
            <template v-for="{ locale, value } in item.value">
              <div :key="locale" class="data-reader-localization">
                <div class="localization-value" :title="value">
                  {{ value }}
                </div>
                <InheritanceSourceMenu
                  v-if="uimodel"
                  v-bind="item.labelSource[locale]"
                  :key-pattern="uimodel.key"
                >
                  <template #activator="{ on, attrs, hasSource }">
                    <v-icon
                      v-if="hasSource"
                      v-on="on"
                      v-bind="attrs"
                      small
                      class="ml-1"
                      :data-test-id="'locale_inheritance_source_' + locale"
                    >
                      mdi-graph
                    </v-icon>
                  </template>
                </InheritanceSourceMenu>
              </div>
            </template>
          </div>
          <div
            v-else-if="
              item.isSimple && item.value !== null && item.value !== undefined
            "
            class="data-reader-value"
            :data-test-id="'tree_node_' + item.path + '_value'"
          >
            {{ item.value }}
          </div>
          <div
            v-else-if="item.isSimple"
            class="data-reader-value text--disabled font-italic"
            :data-test-id="'tree_node_' + item.path + '_value'"
          >
            NULL
          </div>
          <div
            v-else-if="!item.isSimple && item.children.length === 0"
            class="data-reader-value text--disabled font-italic"
            :data-test-id="'tree_node_' + item.path + '_value'"
          >
            {{ item.type === "array" ? "NO ITEMS" : "NO PROPERTIES" }}
          </div>
          <v-divider
            v-if="item.description || item.source.inheritedData !== undefined"
            class="mx-2"
            vertical
          />
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-if="item.description"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-2"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <div class="help-tooltip">
                <div v-if="item.description">
                  <span v-html="$sanitize(item.description)"></span>
                </div>
                <div v-if="item.comment">{{ item.comment }}</div>
              </div>
            </v-tooltip>

            <InheritanceSourceMenu
              v-if="uimodel"
              v-bind="item.source"
              :key-pattern="uimodel.key"
            >
              <template #activator="{ on, attrs, hasSource }">
                <v-icon
                  v-if="hasSource"
                  v-on="on"
                  v-bind="attrs"
                  :color="isInherited(item) ? 'orange' : undefined"
                  small
                  :data-test-id="'inheritance_source_' + item.path"
                >
                  mdi-graph
                </v-icon>
              </template>
            </InheritanceSourceMenu>
          </div>
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import InheritanceSourceMenu from "./editors/controls/InheritanceSourceMenu.vue";
import dataEditorMixin from "../../../mixins/data-editor-mixin";

export default {
  mixins: [dataEditorMixin],

  components: {
    InheritanceSourceMenu,
  },

  props: {
    data: {
      type: [Object, Array],
      required: false,
    },

    schema: {
      type: Object,
      required: true,
    },

    uimodel: {
      type: Object,
      required: false,
    },

    title: {
      type: String,
      required: false,
      default: "ROOT",
    },

    disableJson: {
      type: Boolean,
      required: false,
      default: false,
    },

    hideRootNode: {
      type: Boolean,
      required: false,
      default: false,
    },

    initialClosed: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      tree: [],
      buildingTree: false,
      previewAsJson: false,
      jsonData: this.data,
      copyEnabled: false,
    };
  },

  mounted() {
    this.buildTree();
  },

  watch: {
    data: {
      handler: function () {
        this.buildTree();
      },
      deep: true,
    },

    schema: {
      handler: function () {
        this.buildTree();
      },
      deep: true,
    },
  },

  methods: {
    async buildTree() {
      try {
        this.buildingTree = true;
        let data = this.data;
        if (this.uimodel) {
          data = await this.getPreviewData();
        }
        this.jsonData = data;
        const rootNode = this.buildLeaf(this.title, data, this.schema, "$");
        if (this.hideRootNode) {
          this.tree = rootNode?.children;
        } else {
          this.tree = [rootNode];
        }

        if (!this.initialClosed) {
          //open all leafs
          this.$nextTick(() => this.$refs?.readerTree?.updateAll(true));
        }
      } finally {
        this.buildingTree = false;
      }
    },

    buildLeaf(property, data, schema, path, title) {
      let leaf = {
        id: this.$uuid.v4(),
      };

      if (property === this.title) {
        path = "$";
      } else {
        path = path ? path + "." + property : property;
      }

      const name = title ?? schema?.title?.values?.["en-GB"] ?? property;
      let valueType = this.$getType(data);

      if (schema === true) {
        //this is an additional property whose parent has
        //additionalProperties set to "true", so any schema is allowed here
        if (
          valueType === "string" &&
          ((data.includes("{") && data.includes("}")) ||
            (data.includes("[") && data.includes("]")))
        ) {
          //if the string seems to be an stringified object
          //try to parse it
          try {
            data = JSON.parse(data);
            valueType = Array.isArray(data) ? "array" : "object";
          } catch (e) {
            valueType = "string";
          }
        }
        schema = { type: valueType };
        if (valueType === "object") {
          schema.additionalProperties = true;
        } else if (valueType === "array") {
          schema.items = true;
        }
      }
      const type = schema?.type;
      const isInvalid =
        data != undefined &&
        type !== valueType &&
        !!data &&
        !(type === "integer" && Number.isInteger(data));

      this.$set(leaf, "invalid", isInvalid);
      if (schema?.description?.values)
        this.$set(leaf, "description", schema.description.values?.["en-GB"]);
      if (schema?.dataUI?.comment)
        this.$set(leaf, "comment", schema?.dataUI?.comment);

      if (!leaf.invalid) {
        if (type === "object") {
          let children = [];
          let schemaProperties = this.getOrderedProperties(schema?.properties);
          if (schemaProperties) {
            for (let p in schemaProperties) {
              children.push(
                this.buildLeaf(p, data?.[p], schemaProperties?.[p], path)
              );
            }
          }

          if (this.$isPlainObject(data)) {
            //add all remaining properties to the tree
            Object.keys(data).forEach((key) => {
              if (schemaProperties?.[key]) return;
              if (schema?.additionalPropertiesTypeKey === "i18n") {
                const localesSet = new Set(
                  this.supportedLocales.concat(Object.keys(data))
                );
                let locales = Array.from(localesSet).filter(
                  (locale) => !locale.startsWith("_")
                );
                const translations = locales.map((locale) => {
                  return {
                    locale,
                    value: locale + ": " + (data?.[locale] ?? "NULL"),
                  };
                });
                const sources = locales.reduce((sources, locale) => {
                  const localePath = path + "." + locale;
                  const source = this.getSourceInformation(localePath);
                  this.$set(sources, locale, source);
                  return sources;
                }, {});

                this.$set(leaf, "value", translations);
                this.$set(leaf, "labelSource", sources);
                this.$set(leaf, "isI18n", true);
                this.$set(leaf, "isSimple", true);
              } else {
                children.push(
                  this.buildLeaf(
                    key,
                    data?.[key],
                    schema?.additionalProperties ?? {},
                    path
                  )
                );
              }
            });
          }
          this.$set(leaf, "children", children);
        } else if (type == "array") {
          let children = [];
          if (data != undefined && Array.isArray(data) && data.length > 0) {
            data.forEach((item, index) => {
              const title = this.getArrayItemTitle(item) ?? index;
              children.push(
                this.buildLeaf(index, item, schema?.items ?? true, path, title)
              );
            });
          }
          this.$set(leaf, "children", children);
        } else {
          //primitive type
          const isCountry = schema?.dataUI?.editor === "CountrySelect";
          if (isCountry && !!data) {
            //If the value is a country code, display the name instead of the code
            const country = this.countries.find(({ code }) => code === data);
            if (country?.name) {
              data = country.name + " (" + data + ")";
            }
          }

          this.$set(leaf, "value", data);
          this.$set(leaf, "isSimple", true);
        }
      }

      this.$set(leaf, "type", type);
      this.$set(leaf, "name", name);
      this.$set(leaf, "path", path);
      this.$set(leaf, "source", this.getSourceInformation(path));
      return leaf;
    },

    getSourceInformation(path) {
      const source = this.getSource(path);
      const inheritedDomain =
        source?.inheritSourceDomainId ?? source?.sourceDomainId;
      const inheritedKeyPattern =
        source?.inheritSourceKeyPattern ?? source?.sourceKeyPattern;
      return {
        inheritedData: source?.inheritValue,
        inheritedDomain,
        inheritedKeyPattern,
      };
    },

    async getPreviewData() {
      const preview =
        await this.$store.$coreApi.coreConfigurationApi.getPreviewMergedData(
          this.selectedDomain,
          this.uimodel.keySpace,
          this.uimodel.key,
          this.data,
          this.schema
        );

      if (!preview?.ok) return {};
      const data = await preview.json();
      return data?.mergedData?.data ?? {};
    },

    isInherited(item) {
      //Check if the items data exists in the initial data
      //if not, it is inherited
      const path = item.path.replace("$.", "");
      const data = this.$getObjectValueByPath(this.data, path);
      return data === undefined || data === null;
    },

    getArrayItemTitle(item) {
      if (this.$isPlainObject(item)) {
        return item?.name ?? item?.text ?? item?.value;
      }
      return item;
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    supportedLocales() {
      return this.$store.state.localization.supportedLocales;
    },

    formattedJSON() {
      const data = this.jsonData ?? {};
      return JSON.stringify(data, null, "\t");
    },

    countries() {
      return this.$getCountries();
    },
  },
};
</script>

<style scoped>
.data-reader-container {
  position: relative;
}

.data-reader-container > .data-reader-toolbar {
  display: flex;
  margin-bottom: 12px;
}

.data-reader-container .data-reader-json:not(.copy-enabled) {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.data-reader-container > .data-reader-toolbar .data-reader-control {
  margin-top: 0;
  padding: 0;
}

.data-reader::v-deep .v-treeview-node__level {
  width: 36px;
}

.data-reader::v-deep .v-treeview-node__toggle {
  margin-left: 12px;
}

.data-reader.v-treeview--dense::v-deep .v-treeview-node__root {
  min-height: 28px;
}

.data-reader.v-treeview--dense::v-deep
  .v-treeview-node__root
  > .v-treeview-node__content
  > * {
  cursor: text;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.data-reader.v-treeview--dense::v-deep
  .v-treeview-node__root
  > .v-treeview-node__content
  .data-reader-localization-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.data-reader.v-treeview--dense::v-deep
  .v-treeview-node__root
  > .v-treeview-node__content
  .data-reader-localization-list
  > .data-reader-localization {
  display: flex;
  flex-direction: row;
}

.data-reader.v-treeview--dense::v-deep
  .v-treeview-node__root
  > .v-treeview-node__content
  .data-reader-localization-list
  > .data-reader-localization
  > .localization-value {
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.data-reader.v-treeview--dense::v-deep
  .v-treeview-node__root
  > .v-treeview-node__content
  .data-reader-localization-list
  > .data-reader-localization
  + .data-reader-localization::before {
  content: "/";
  margin: 0 8px;
}

.data-reader .data-reader-label {
  display: flex;
  margin-right: 12px;
  font-weight: 900;
}

.data-reader .data-reader-value {
  display: flex;
  text-overflow: clip;
  white-space: pre-wrap;
  word-break: break-word;
}

.data-reader .invalid {
  color: red;
}

.inheritance-source-text {
  background-color: white;
  font-size: 0.75rem;
}

.help-tooltip {
  max-width: 500px;
  word-break: normal;
  overflow-wrap: anywhere;
}

.data-reader-loading-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 192px;
}
</style>