import { render, staticRenderFns } from "./Reader.vue?vue&type=template&id=dcfe20ca&scoped=true&"
import script from "./Reader.vue?vue&type=script&lang=js&"
export * from "./Reader.vue?vue&type=script&lang=js&"
import style0 from "./Reader.vue?vue&type=style&index=0&id=dcfe20ca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcfe20ca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCheckbox,VDivider,VIcon,VOverlay,VProgressCircular,VSwitch,VTooltip,VTreeview})
