var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.buildingTree)?_c('div',{staticClass:"data-reader-loading-overlay"},[_c('v-overlay',{attrs:{"absolute":"","color":"white"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"96","color":"primary"}})],1)],1):_c('div',{staticClass:"data-reader-container"},[(!_vm.disableJson)?_c('div',{staticClass:"data-reader-toolbar"},[_c('v-switch',{staticClass:"data-reader-control",attrs:{"inset":"","hide-details":"","label":"Preview as JSON"},model:{value:(_vm.previewAsJson),callback:function ($$v) {_vm.previewAsJson=$$v},expression:"previewAsJson"}}),(_vm.uimodel && _vm.previewAsJson)?_c('div',{staticClass:"d-flex"},[_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}),_c('v-checkbox',{staticClass:"data-reader-control mr-3",attrs:{"hide-details":"","label":"Enable copy"},model:{value:(_vm.copyEnabled),callback:function ($$v) {_vm.copyEnabled=$$v},expression:"copyEnabled"}}),_c('div',{staticClass:"warning--text"},[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert")]),_vm._v(" Copying data from one key to another could render the inheritance functionality useless, so use with caution! ")],1)],1):_vm._e()],1):_vm._e(),(_vm.previewAsJson)?_c('div',[_c('pre',{class:{
        'data-reader-json': true,
        'copy-enabled': _vm.copyEnabled || !_vm.uimodel,
      }},[_vm._v(_vm._s(_vm.formattedJSON))])]):_c('v-treeview',{ref:"readerTree",staticClass:"data-reader",attrs:{"items":_vm.tree,"open-all":"","open-on-click":"","dense":"","hoverable":""},scopedSlots:_vm._u([{key:"label",fn:function(ref){
      var item = ref.item;
return [_c('div',{class:{
          'd-flex': true,
          'justify-start': true,
          'align-center': true,
          invalid: item.invalid,
        }},[_c('div',{staticClass:"data-reader-label",attrs:{"data-test-id":'tree_node_' + item.path + '_name'}},[_vm._v(" "+_vm._s(item.name + (item.isSimple ? ":" : ""))+" ")]),(item.invalid)?_c('div',{staticClass:"data-reader-value",attrs:{"data-test-id":'tree_node_' + item.path + '_value'}},[_vm._v(" INVALID DATA ")]):(item.isI18n)?_c('div',{staticClass:"data-reader-localization-list",attrs:{"data-test-id":'tree_node_' + item.path + '_value'}},[_vm._l((item.value),function(ref){
        var locale = ref.locale;
        var value = ref.value;
return [_c('div',{key:locale,staticClass:"data-reader-localization"},[_c('div',{staticClass:"localization-value",attrs:{"title":value}},[_vm._v(" "+_vm._s(value)+" ")]),(_vm.uimodel)?_c('InheritanceSourceMenu',_vm._b({attrs:{"key-pattern":_vm.uimodel.key},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
        var hasSource = ref.hasSource;
return [(hasSource)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":"","data-test-id":'locale_inheritance_source_' + locale}},'v-icon',attrs,false),on),[_vm._v(" mdi-graph ")]):_vm._e()]}}],null,true)},'InheritanceSourceMenu',item.labelSource[locale],false)):_vm._e()],1)]})],2):(
            item.isSimple && item.value !== null && item.value !== undefined
          )?_c('div',{staticClass:"data-reader-value",attrs:{"data-test-id":'tree_node_' + item.path + '_value'}},[_vm._v(" "+_vm._s(item.value)+" ")]):(item.isSimple)?_c('div',{staticClass:"data-reader-value text--disabled font-italic",attrs:{"data-test-id":'tree_node_' + item.path + '_value'}},[_vm._v(" NULL ")]):(!item.isSimple && item.children.length === 0)?_c('div',{staticClass:"data-reader-value text--disabled font-italic",attrs:{"data-test-id":'tree_node_' + item.path + '_value'}},[_vm._v(" "+_vm._s(item.type === "array" ? "NO ITEMS" : "NO PROPERTIES")+" ")]):_vm._e(),(item.description || item.source.inheritedData !== undefined)?_c('v-divider',{staticClass:"mx-2",attrs:{"vertical":""}}):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [(item.description)?_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2"},'v-icon',attrs,false),on),[_vm._v(" mdi-help-circle-outline ")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"help-tooltip"},[(item.description)?_c('div',[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$sanitize(item.description))}})]):_vm._e(),(item.comment)?_c('div',[_vm._v(_vm._s(item.comment))]):_vm._e()])]),(_vm.uimodel)?_c('InheritanceSourceMenu',_vm._b({attrs:{"key-pattern":_vm.uimodel.key},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
          var hasSource = ref.hasSource;
return [(hasSource)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":_vm.isInherited(item) ? 'orange' : undefined,"small":"","data-test-id":'inheritance_source_' + item.path}},'v-icon',attrs,false),on),[_vm._v(" mdi-graph ")]):_vm._e()]}}],null,true)},'InheritanceSourceMenu',item.source,false)):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }